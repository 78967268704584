import React, { Component } from "react"
import Header from "./header/header.component"
import styled from "styled-components"
import * as utils from "../GlobalStyles/utils"
import FormButton from "../form-button/form-button.component"
import Messages from "../Message/message.data"
import Message from "../Message/message.component"
import { BodyText } from "../texts"
import { Title } from "../GlobalStyles/utils"
import show from "../../images/show.png"
import showActive from "../../images/show-pink.png"
import { Column } from "../styled"
import { UpdatedButton } from "@youngagency/young-ui"

const Validator = styled.label`
  ${utils.inputValidator};
`

const FormButtonStyled = styled.button`
  ${utils.formButton};
  border-radius: 30px;
  cursor: pointer;
`

const IconImg = styled.img`
  width: 20px;
  position: absolute;
  margin-top: 12px;
  right: 10px;
  height: 45px;
  object-fit: contain;
  cursor: pointer;
`
let userIdParam, codeParam
class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      confirmPassword: "",
      confirmEmailValid: false,
      passwordValid: false,
      data: [],
    }
    this.body = styled.div`
      height: auto;
      min-height: 100vh;
      width: 100%;
      width: -webkit-fill-available;
      width: -moz-available;
      ${utils.marginH("50px")};
      ${utils.flexCenter};
      ${utils.media
        .tablet`margin-left:5%;margin-right:5%;min-height:calc(100vh - 60px)`};
    `
    this.Content = styled.div`
      ${utils.flexCenter};
      ${utils.flexColumn};
      width: 812px;
      max-width: 100%;
      ${utils.media.desktop`width: 400px;padding-bottom:30px;`};
    `
    this.InputLine = styled.div`
      ${utils.inputLine};
      justify-content: center;
    `
    this.InputContainer = styled.div`
      ${utils.inputContainer};
      width: 100%;
      align-items: center;
      margin-top: 60px;
      max-width: 400px;
    `
    this.Input = styled.input`
      ${utils.input};
      border-radius: 30px;
    `
  }

  addMessage = msg => {
    if (!this.state.data.includes(msg)) {
      var newArray = this.state.data.slice()
      newArray.push(msg)
      this.setState(() => ({
        data: newArray,
      }))
      setTimeout(
        function () {
          this.setState(prevState => ({
            data: prevState.data.filter(el => el != msg),
          }))
        }.bind(this),
        3000
      )
    }
  }

  handleInputChange = event => {
    const name = event.target.name
    const value = event.target.value

    // setState receives a callback for when the state has finished updating
    this.checkValid(name, value)
  }
  checkValid = (name, value) => {
    let passwordValid = this.state.passwordValid
    let confirmPasswordValid = this.state.confirmPasswordValid

    switch (name) {
      case "password":
        if (value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{8,}$/i) !== null) {
          passwordValid = true
        } else passwordValid = false
        if (passwordValid)
          if (value === this.state.confirmPassword) confirmPasswordValid = true
          else confirmPasswordValid = false
        break
      case "confirmPassword":
        if (value === this.state.password) {
          confirmPasswordValid = true
        } else {
          confirmPasswordValid = false
        }
        break
      default:
        break
    }

    this.setState({
      [name]: value,
      passwordValid: passwordValid,
      confirmPasswordValid: confirmPasswordValid,
    })
  }
  showPass = (s, id) => {
    if (document.querySelector("input[name=" + s + "]").type === "text") {
      document.querySelector("input[name=" + s + "]").type = "password"
      document.getElementById(id).src = show
    } else {
      document.querySelector("input[name=" + s + "]").type = "text"
      document.getElementById(id).src = showActive
    }
  }

  componentDidMount() {
    const userId = this.getQueryParam("userId")
    const code = this.getQueryParam("code")
    userIdParam = userId
    codeParam = code
  }
  resetPassword = () => {
    if (userIdParam == undefined || codeParam == undefined) {
      userIdParam = this.getQueryParam("userId")
      codeParam = this.getQueryParam("code")
    }
    var data = {
      userId: userIdParam,
      code: codeParam,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    }
    /*formData.append("userId", userIdParam);
      formData.append("code", decodeURIComponent(codeParam));
      formData.append('password', this.state.password);
      formData.append('confirmPassword', this.state.confirmPassword);*/
    var formBody = []
    for (var property in data) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue
      if (encodedKey === "code") {
        encodedValue = data[property]
      } else {
        encodedValue = encodeURIComponent(data[property])
      }
      formBody.push(encodedKey + "=" + encodedValue)
    }
    formBody = formBody.join("&")
    fetch(process.env.GATSBY_IDENTITY_URL + "/api/account/resetpassword", {
      method: "POST",
      headers: {
        Accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    })
      .then(response => {
        if (response.status === 200) {
          this.addMessage(Messages.passChanged)
          return 0
        }
        return response.json()
      })
      .then(result => {
        if (
          result["error_description"] !== null &&
          result["error_description"] !== undefined
        ) {
          switch (result["error_description"]) {
            case "ERR.TOKEN_EXPIRED":
              this.addMessage(Messages.tokenExpired)
              break
            case "ERR.USER_NOT_EXISTS":
              this.addMessage(Messages.emailNotExist)
              break
            default:
              this.addMessage(Messages.genericError)
              break
          }
        }
      })
      .catch(error => {
        this.addMessage(Messages.genericError)
        console.log(error)
      })
  }
  showPassPwd = () => {
    this.showPass("password", "iconPass")
  }
  getQueryParam = param => {
    var result = window.location.search.match(
      new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
    )

    return result ? result[3] : false
  }
  showConfirmPassPwd = () => {
    this.showPass("confirmPassword", "iconConfirmPass")
  }
  render() {
    const { t, right } = this.props

    return (
      <div style={{ position: "relative" }}>
        <Message data={this.state.data} />
        <Header right={right} />
        <this.body>
          <this.Content>
            <Title color="#1a1a1a">{t("reset_password.title")}</Title>

            <BodyText color={"#1a1a1a"} style={{ textAlign: "center" }}>
              {t("reset_password.text")}
            </BodyText>
            <Column gap="xs">
              <this.InputLine>
                <this.InputContainer>
                  <this.Input
                    placeholder={t("login.second_step.password")}
                    type="password"
                    name="password"
                    className="password-input"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    required=""
                    style={{
                      width: "100% !important",
                    }}
                  />
                  <IconImg
                    id="iconPass"
                    src={show}
                    onClick={this.showPassPwd}
                  />

                  <Validator
                    className={
                      !this.state.passwordValid && this.state.password !== ""
                        ? "active"
                        : ""
                    }
                  >
                    {t("login.invalid_password")}
                  </Validator>
                </this.InputContainer>
              </this.InputLine>
              <this.InputLine>
                <this.InputContainer style={{ marginTop: "0px" }}>
                  <this.Input
                    placeholder={t("login.second_step.confirm_password")}
                    type="password"
                    name="confirmPassword"
                    className="password-input"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required=""
                    style={{
                      width: "100% !important",
                    }}
                  />
                  <IconImg
                    id="iconConfirmPass"
                    src={show}
                    onClick={this.showConfirmPassPwd}
                  />
                  <Validator
                    className={
                      !this.state.confirmPasswordValid &&
                      this.state.confirmPassword !== ""
                        ? "active"
                        : ""
                    }
                  >
                    {t("login.invalid_confirm_password")}
                  </Validator>
                </this.InputContainer>
              </this.InputLine>
              {/* <FormButton
                text={t("buttons.reset_password")}
                callback={this.resetPassword}
                style={{ ...FormButtonStyled, width: "100% !important" }}
                active={
                  this.state.passwordValid && this.state.confirmPasswordValid
                }
              /> */}
              <UpdatedButton
                variant="primary"
                colorScheme="pink"
                size="sm"
                style={{
                  width: "calc(100%)",
                  borderRadius: "100px",
                }}
                onClick={this.resetPassword}
                disabled={
                  !(this.state.passwordValid && this.state.confirmPasswordValid)
                }
              >
                {t("buttons.reset_password")}
              </UpdatedButton>
            </Column>
          </this.Content>
        </this.body>
      </div>
    )
  }
}

export default ResetPassword
