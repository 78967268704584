import { css } from 'styled-components';
const headerStyles = css`
  height: 36px;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  width: -moz-available;
  z-index: 1;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 12px;
  padding-bottom: 12px;
`;
export default headerStyles;
