import React from "react"
import ResetPassword from "../components/login/reset-password.component"
import { graphql, Link } from "gatsby"
import AuthLayout from "../components/login/layout"
import { Row } from "../components/styled"
import { UpdatedButton, Text, Box, useMediaQuery } from "@youngagency/young-ui"
import Seo from "../components/seo"
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"

const Reset = ({ pageContext: { i18n } }) => {
  const { t } = useI18next()
  const context = React.useContext(I18nextContext)
  const lng = context.language
  return (
    <AuthLayout>
      <Seo
        seoIdentifier="/reset"
        originalPath={i18n.originalPath}
        lang={lng}
        title={t("reset_password.seo.title")}
        description={t("reset_password.seo.description")}
      />
      <ResetPassword t={t}></ResetPassword>
    </AuthLayout>
  )
}

const Right = () => {
  const { t } = useI18next()
  const { isMobile } = useMediaQuery()

  return (
    <Box>
      <Row gap="sm">
        {!isMobile && (
          <Text type="paragraph">{t("login.already_registered")}</Text>
        )}
        <Link
          to="/login"
          style={{
            textDecoration: "none",
          }}
        >
          <UpdatedButton
            variant="outline"
            size="xs"
            style={{
              backgroundColor: "white",
              border: "#ff298a solid",
              color: "#ff298a",
            }}
          >
            {t("buttons.join_now")}
          </UpdatedButton>
        </Link>
      </Row>
    </Box>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Reset
