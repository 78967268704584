import styled from "styled-components"
import React, { Component } from "react"
import { translate, Trans } from "react-i18next"
import * as utils from "../../GlobalStyles/utils"

import headerStyles from "./headerStyles"

class Header extends Component {
  render() {
    const YoungHeader = styled.div`
      ${headerStyles};
      @media screen and (min-width: 768px) {
        position: fixed;
      }
    `

    const Container = styled.div`
      height: 100%;
      position: relative;
      ${utils.flexCenter};
    `
    return (
      <YoungHeader style={this.props.style}>
        <Container>{this.props.left}</Container>
        <Container>{this.props.center}</Container>
        <Container>{this.props.right}</Container>
      </YoungHeader>
    )
  }
}
export default Header
